require('lightgallery');
require('vanilla-cookieconsent');

/* obrazky nacteny */
function initImagesLoaded() {
    $('html').addClass('window-on-load');
}

/* centrovani kazdeho obrazku globalne */
function initCenterImageLoad() {
    if ($('.js-image-to-show').length) {

        $('.js-image-to-show:not(.active)').filter(function () {

            var img = $(this);

            if (img.height()) {
                imgCallback(img);
                return false;
            } else {
                return true;
            }

        }).on('load', function () {
            var img = $(this);
            imgCallback(img);
        });
    }

    function imgCallback(image) {

        var imageHeight = image.height();
        var imageWidth = image.width();

        var imageRatio = imageWidth / imageHeight;
        var frameRatio = 1024 / 684;

        if (imageRatio < frameRatio) {
            image.addClass('full-height');
            image.addClass('active');
            setTimeout(function () {
                image.addClass('visible');
            }, 100);
        } else {
            image.addClass('full-width');
            image.addClass('active');
            setTimeout(function () {
                image.addClass('visible');
            }, 100);
        }
    }
}

/* lang klikatko - header */
function initLangs() {
    $('.js-language-selected').click(function ()
    {
        $('.js-language-wrapper').toggleClass('active');
    });
}

/* funkce na max počet a odpočet znaků v textarea */
function initTextareaMaxlength() {
// Get all textareas that have a "maxlength" property. Now, and when later adding HTML using jQuery-scripting:
    $('textarea[maxlength]').each(function () {

        var maxlength = $(this).attr('maxlength');
        var val = $(this).val();
        var vallenght = val.toString().length;
        var infomaxlengthcount = $(this).next().find('.js-infomaxlengthcount');
        // @ts-ignore
        infomaxlengthcount.html(maxlength - vallenght);
        $(this).on('input paste', function () {
// Store the maxlength and value of the field.
            maxlength = $(this).attr('maxlength');
            val = $(this).val();
            vallenght = val.toString().length;
            // Trim the field if it has content over the maxlength.
            // @ts-ignore
            if (val.toString().length > maxlength) {
                // @ts-ignore
                $(this).val(val.slice(0, maxlength));
            }
            // @ts-ignore
            infomaxlengthcount.html(maxlength - vallenght);
        });
    });
}

/* sescroluje na input s errorem - prvni od vrchu */
function initFormErrorScroll() {
    var firstErrorElement = $('form').find('.form-row.error').first();
    if (firstErrorElement.length) {
        $('html,body').animate({
            scrollTop: firstErrorElement.offset().top
        }, 1000);
    }
}

/* hp top mista a aktuality kliker */
function initTipsClick() {
    $('.js-tips-header').click(function (e) {
        e.preventDefault();

        if (!$(this).hasClass('active')) {
            $('.js-tips-header').removeClass('active');
            $(this).addClass('active');
            var target = $(this).attr('data-target');

            $('.js-list-tips').removeClass('visible');
            setTimeout(function () {
                $('.js-list-tips').removeClass('active');
                $(target).addClass('active');
                setTimeout(function () {
                    $(target).addClass('visible');
                }, 200);
            }, 200);
        }
    });
}

/* hp kolecka kliker */
function initCirclesClick() {
    $('.js-circle').click(function (e) {
        e.preventDefault();

        if (!$(this).hasClass('active')) {
            $('.js-circle').removeClass('active');
            $(this).addClass('active');
            var target = $(this).attr('data-target');

            $('.js-list-selection').removeClass('visible');
            setTimeout(function () {
                $('.js-list-selection').removeClass('active');
                $(target).addClass('active');
                setTimeout(function () {
                    $(target).addClass('visible');

                    // reset vybranych checkboxu
                    $('.js-list-selection input[type="checkbox"]').prop("checked", false);
                }, 200);
            }, 200);
        }
    });
}

$(window).on("load", function () {
    initImagesLoaded();
});

$(() => {
    initLangs();
    initCenterImageLoad();
    initTextareaMaxlength();
    initFormErrorScroll();
    initTipsClick();
    initCirclesClick();

    // @ts-ignore
    $("main").lightGallery({
        lang: {
            allPhotos: ''
        },
        selector: $(".js-gallery-item"),
        loop: true,
        thumbWidth: 160
    });
});

// @ts-ignore
var cc = initCookieConsent();

cc.run({
    auto_language: "document",
    autoclear_cookies: true,                   // default: false
    page_scripts: true,                        // default: false

    onChange: function (cookie, changed_preferences) {
        // ONCHANGE: If analytics category is disabled => disable google analytics
        if (!cookie.level.includes('analytics')) {
            // @ts-ignore
            typeof gtag === 'function' && gtag('consent', 'update', {
                'analytics_storage': 'denied'
            });
        }
    },

    languages: {
        'cs': {
            consent_modal: {
                title: 'Používáme cookies!',
                description: 'K provozování těchto webových stránek společnost Destinační agentura České středohoří,&nbsp;o.p.s. používá soubory cookies a&nbsp;podobné technologie v&nbsp;nezbytném rozsahu. Pro&nbsp;užití volitelných souborů cookies za&nbsp;účelem zlepšení a&nbsp;personalizace vaší&nbsp;zkušenosti shromažďování analytických údajů, jako&nbsp;je počet návštěv a&nbsp;zdrojů návštěvnosti, poskytování reklamy a&nbsp;komunikace s&nbsp;třetími stranami žádá Destinační agentura České středohoří,&nbsp;o.p.s. váš&nbsp;souhlas. <span style="margin-top:10px;display:block;"><button type="button" data-cc="c-settings" class="cc-link">Upravit&nbsp;nastavení</button></span>',
                primary_btn: {
                    text: 'Potvrdit vše',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Odmítnout vše',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Předvolby souborů cookie',
                save_settings_btn: 'Uložit nastavení',
                accept_all_btn: 'Potvrdit vše',
                reject_all_btn: 'Odmítnout vše',
                close_btn_label: 'Zavřít',
                cookie_table_headers: [
                    {col1: 'Název'},
                    {col2: 'Doména'},
                    {col3: 'Expirace'},
                    {col4: 'Popis'}
                ],
                blocks: [
                    {
                        title: 'Používání souborů cookie',
                        description: 'Soubory cookie používáme k&nbsp;zajištění základních funkcí webových stránek a&nbsp;ke zlepšení vašeho online zážitku. U&nbsp;každé kategorie si můžete zvolit, zda&nbsp;se chcete přihlásit nebo&nbsp;odhlásit.'
                    }, {
                        title: 'Nezbytné',
                        description: 'Tyto cookies jsou vyžadovány pro&nbsp;správnou funkčnost našich webových stránek a&nbsp;v&nbsp;našem systému je nelze vypnout.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: 'cc_cookie',
                                col2: '.stredohori.cz',
                                col3: '6 měsíců',
                                col4: 'Uchovává informace o&nbsp;souhlasu s&nbsp;používáním cookies.',
                                is_regex: true
                            }
                        ]
                    }, {
                        title: 'Výkonnostní a&nbsp;analytické',
                        description: 'Tyto soubory cookie umožňují webové stránce zapamatovat si volby, které&nbsp;jste provedli v&nbsp;minulosti.',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '_ga',       // match all cookies starting with "_ga"
                                col2: '.stredohori.cz',
                                col3: '2 roky',
                                col4: 'Tento název souboru cookie je spojen se&nbsp;službou Google Analytics, což&nbsp;je významná aktualizace běžněji používané analytické služby společnosti Google. Tento&nbsp;soubor cookie se&nbsp;používá k&nbsp;rozlišení jedinečných uživatelů přiřazením náhodně vygenerovaného čísla jako identifikátoru klienta. Je&nbsp;obsažen v&nbsp;každém požadavku na&nbsp;stránku na&nbsp;webu a&nbsp;slouží k&nbsp;výpočtu údajů o&nbsp;návštěvnících, relacích a&nbsp;kampaních pro&nbsp;analytické přehledy&nbsp;webu.',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: '.stredohori.cz',
                                col3: '1 den',
                                col4: 'Tento soubor cookie je nastaven službou Google Analytics. Ukládá&nbsp;a&nbsp;aktualizuje jedinečnou hodnotu pro&nbsp;každou navštívenou stránku a&nbsp;slouží k&nbsp;počítání a&nbsp;sledování zobrazení stránek.',
                            },
                            {
                                col1: '_gat',
                                col2: '.stredohori.cz',
                                col3: '1 minuta',
                                col4: 'Tento soubor cookie je nastaven službou Google Analytics. Používá se pro omezení počtu požadavků.',
                            }
                        ]
                    }
                ]
            }
        },
        'en': {
            consent_modal: {
                title: 'We use cookies!',
                description: 'Destination Agency of the Central Bohemian Uplands uses only cookies and similar technologies to the extent necessary for operation of this website. Destination Agency of the Central Bohemian Uplands asks for your consent to use optional cookies to improve and personalize your experience by collecting analytical data such as number of visits and traffic sources, providing advertising and communicating with third parties. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Reject all',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie preferences',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'},
                    {col3: 'Expiration'},
                    {col4: 'Description'}
                ],
                blocks: [
                    {
                        title: 'Cookie usage',
                        description: 'Říp,&nbsp;o.p.s. uses cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data.'
                    }, {
                        title: 'Strictly necessary cookies',
                        description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: 'cc_cookie',
                                col2: '.stredohori.cz',
                                col3: '6 months',
                                col4: 'Stores information about consent to the use of cookies.',
                                is_regex: true
                            }
                        ]
                    }, {
                        title: 'Performance and Analytics cookies',
                        description: 'These cookies allow the website to remember the choices you have made in the past',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '_ga',       // match all cookies starting with "_ga"
                                col2: '.stredohori.cz',
                                col3: '2 years',
                                col4: 'This cookie name is associated with Google Analytics - which is a significant update to Google\'s more commonly used analytics service. This cookie is used to distinguish unique users by assigning a randomly generated number as a client identifier. It is included in each page request in a site and used to calculate visitor, session and campaign data for the sites analytics reports.',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: '.stredohori.cz',
                                col3: '1 day',
                                col4: 'This cookie is set by Google Analytics. It stores and update a unique value for each page visited and is used to count and track pageviews.',
                            },
                            {
                                col1: '_gat',
                                col2: '.stredohori.cz',
                                col3: '1 minute',
                                col4: 'This cookie is set by Google Analytics. Used to throttle request rate.',
                            }
                        ]
                    }, {
                        title: 'Advertisement and Targeting cookies',
                        description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                        toggle: {
                            value: 'targeting',
                            enabled: false,
                            readonly: false
                        }
                    }
                ]
            }
        },
        'de': {
            consent_modal: {
                title: 'Wir verwenden Cookies!',
                description: 'Destinationsagentur Böhmisches Mittelgegirge,&nbsp;o.p.s. verwendet Cookies und ähnliche Technologien nur in dem für den Betrieb dieser Website erforderlichen Umfang. Destinationsagentur Böhmisches Mittelgegirge,&nbsp;o.p.s. bittet Sie um Ihre Zustimmung, optionale Cookies zu verwenden, um Ihre Erfahrung zu verbessern und zu personalisieren, indem es analytische Daten wie die Anzahl der Besuche und Verkehrsquellen sammelt, Werbung bereitstellt und mit Dritten kommuniziert. <span style="margin-top:10px;display:block;"><button type="button" data-cc="c-settings" class="cc-link">Einstellungen anpassen</button></span>',
                primary_btn: {
                    text: 'Alle akzeptieren',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Alles ablehnen',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie-Einstellungen',
                save_settings_btn: 'Einstellungen speichern',
                accept_all_btn: 'Alle akzeptieren',
                reject_all_btn: 'Alles ablehnen',
                close_btn_label: 'Schließen',
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'},
                    {col3: 'Ablauf'},
                    {col4: 'Beschreibung'}
                ],
                blocks: [
                    {
                        title: 'Cookie-Nutzung',
                        description: 'Wir verwenden Cookies, um die Grundfunktionen der Website sicherzustellen und Ihr Online-Erlebnis zu verbessern. Sie können für jede Kategorie wählen, ob Sie sich an- oder abmelden möchten.'
                    }, {
                        title: 'Unbedingt erforderliche Cookies',
                        description: 'Diese Cookies sind für das reibungslose Funktionieren meiner Website unerlässlich. Ohne diese Cookies würde die Website nicht richtig funktionieren.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: 'cc_cookie',
                                col2: '.stredohori.cz',
                                col3: '6 Monate',
                                col4: 'Speichert Informationen über die Zustimmung zur Verwendung von Cookies.',
                                is_regex: true
                            }
                        ]
                    }, {
                        title: 'Leistungs- und Analyse-Cookies',
                        description: 'Diese Cookies ermöglichen es der Website, sich an die Entscheidungen zu erinnern, die Sie in der Vergangenheit getroffen haben.',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '_ga',       // match all cookies starting with "_ga"
                                col2: '.stredohori.cz',
                                col3: '2 Jahre',
                                col4: 'Dieser Cookie-Name ist mit Google Analytics verknüpft – einem bedeutenden Update des häufiger verwendeten Analysedienstes von Google. Dieses Cookie wird verwendet, um eindeutige Benutzer zu unterscheiden, indem eine zufällig generierte Nummer als Client-ID zugewiesen wird. Es ist in jeder Seitenanforderung auf einer Website enthalten und wird verwendet, um Besucher-, Sitzungs- und Kampagnendaten für die Analyseberichte der Website zu berechnen.',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: '.stredohori.cz',
                                col3: '1 Tag',
                                col4: 'Dieses Cookie wird von Google Analytics gesetzt. Es speichert und aktualisiert einen eindeutigen Wert für jede besuchte Seite und wird verwendet, um Seitenaufrufe zu zählen und zu verfolgen.',
                            },
                            {
                                col1: '_gat',
                                col2: '.stredohori.cz',
                                col3: '1 Minute',
                                col4: 'Dieses Cookie wird von Google Analytics gesetzt. Wird verwendet, um die Anforderungsrate zu drosseln.',
                            }
                        ]
                    }
                ]
            }
        }
    }
});

